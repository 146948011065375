'use-strict';
/**
 * Clase para gestionar el almacenamiento local.
 * @class
 */
export class LocalStorage {
    DEVICES_COUNT: {
        audio: number;
        video: number;
        speaker: number;
    };
    C2C_CONFIG: {
        audio: {
            init: {
                active: boolean;
            };
            devices: {
                count: number;
                select: {
                    index: number;
                    label: string | null;
                    id: string | null;
                };
            };
        };
        speaker: {
            devices: {
                count: number;
                select: {
                    index: number;
                    label: string | null;
                    id: string | null;
                };
            };
        };
        video: {
            init: {
                active: boolean;
                hide: boolean;
            };
            devices: {
                count: number;
                select: {
                    index: number;
                    label: string | null;
                    id: string | null;
                };
            };
            settings: {
                quality_index: number;
                fps_index: number;
                best_quality: boolean;
                aspect_ratio: boolean;
            };
        };
    };
    constructor() {
        this.DEVICES_COUNT = {
            audio: 0,
            video: 0,
            speaker: 0,
        };

        this.C2C_CONFIG = {
            audio: {
                init: {
                    active: true,
                },
                devices: {
                    count: 0,
                    select: {
                        index: 0,
                        label: null,
                        id: null,
                    },
                },
            },
            speaker: {
                devices: {
                    count: 0,
                    select: {
                        index: 0,
                        label: null,
                        id: null,
                    },
                },
            },
            video: {
                init: {
                    active: true,
                    hide: false,
                },
                devices: {
                    count: 0,
                    select: {
                        index: 0,
                        label: null,
                        id: null,
                    },
                },
                settings: {
                    quality_index: 0,
                    fps_index: 0,
                    best_quality: false,
                    aspect_ratio: false,
                },
            },
        };
    }
    /**
    * Método para establecer un elemento en el almacenamiento local.
    * @param {string} key - Clave del elemento.
    * @param {string} value - Valor del elemento.
    */
    setItem(key: string, value: string): void {
        localStorage.setItem(key, value);
    }

    /**
     * Método para establecer la configuración.
     * @param {object} config - Configuración a establecer.
     */
    setConfig(config: any): void {
        this.C2C_CONFIG = config;
        this.setItem('C2C_CONFIG', JSON.stringify(this.C2C_CONFIG));
    }
    /**
     * Método para obtener un elemento del almacenamiento local.
     * @param {string} key - Clave del elemento a obtener.
     * @returns {string} El valor del elemento.
     */
    getItem(key: string): string | null {
        return localStorage.getItem(key);
    }
    /**
     * Método para obtener la configuración.
     * @returns {object} La configuración.
     */
    getConfig() : any  {
        const configString = this.getItem('C2C_CONFIG');
        return configString ? JSON.parse(configString) : null;
    }
}


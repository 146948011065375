<div id="miroot">
    <div id="mibody">
        <div id="divisorcentral">
        <!-- Try input -->
        <label for="room" id="labelSala">Push - Sala: ? - Nombre: ?</label>
        <button id="pushBtn" (click)="activaPush()">Pulsa para llamar</button>
        <button id="exitBtn"  (click)="salida()">Pulsa para Salir</button>
    </div>
    </div>
    </div>
    <div id="settings">
        <!-- Opciones de configuración -->
        <button id="settingsCloseBtn" class="fas fa-times"></button>
        <!-- Configuración del micrófono -->
        <label for="audioSource"><i class="fas fa-microphone"></i> Microphone</label>
        <select id="audioSource"></select>
    </div>
'use-strict';
import Swal, { SweetAlertIcon, SweetAlertPosition } from 'sweetalert2';
/**
 * Muestra un mensaje emergente.
 * @param {string} icon - Icono del mensaje ('info', 'success', 'warning', 'error', 'clean', 'toast').
 * @param {string} title - Título del mensaje.
 * @param {string} message - Contenido del mensaje.
 * @param {string} [position='center'] - Posición del mensaje ('top', 'top-start', 'top-end', 'center', 'center-start', 'center-end', 'bottom', 'bottom-start', 'bottom-end').
 * @param {number} [timer=3000] - Duración del mensaje en milisegundos.
 */
export function popupMessage(icon: string, title: string, message: string, position: SweetAlertPosition = 'center', timer: number = 3000) {
    switch (icon) {
        case 'info':
        case 'success':
        case 'warning':
        case 'error':
            Swal.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                position: position,
                icon: icon as SweetAlertIcon,
                title: title,
                html: message,
                showClass: { popup: 'animate__animated animate__fadeInDown' },
                hideClass: { popup: 'animate__animated animate__fadeOutUp' },
            });
            break;
        case 'clean':
            Swal.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                position: position,
                title: title,
                html: message,
                showClass: { popup: 'animate__animated animate__fadeInDown' },
                hideClass: { popup: 'animate__animated animate__fadeOutUp' },
            });
            break;
        case 'toast':
            const Toast = Swal.mixin({
                toast: true,
                position: position,
                icon: 'info',
                showConfirmButton: false,
                timerProgressBar: true,
                timer: timer,
            });
            Toast.fire({
                icon: 'info',
                title: message,
                showClass: { popup: 'animate__animated animate__fadeInDown' },
                hideClass: { popup: 'animate__animated animate__fadeOutUp' },
            });
            break;
        default:
            alert(message);
    }
}

import { Component, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('roomIdInput') roomIdInput!: ElementRef<HTMLInputElement>;
  @ViewChild('userNameInput') userNameInput!: ElementRef<HTMLInputElement>;
  @ViewChild('masterCheckbox') masterCheckbox!: ElementRef<HTMLInputElement>;
  @ViewChild('pushBtn') pushBtn!: ElementRef<HTMLButtonElement>;
  @ViewChild('joinBtn') joinBtn!: ElementRef<HTMLButtonElement>;
  @ViewChild('nocameraBtn') nocameraBtn!: ElementRef<HTMLButtonElement>;
  @ViewChild('audionoCameraBtn') audionoCameraBtn!: ElementRef<HTMLButtonElement>;
  constructor(private route: ActivatedRoute, private router: Router, private cdr: ChangeDetectorRef, private titleService: Title) {


  }

  ngOnInit(): void {
    this.titleService.setTitle('Pruebasinserve');
  }


  joinRoom(): void {
    console.log('JOIN');
    const roomValue = this.roomIdInput.nativeElement.value;
    const nameValue = this.userNameInput.nativeElement.value;
    const master = this.masterCheckbox.nativeElement.checked;
    console.log('Valor de room: ' + roomValue);
    console.log('Valor de name: ' + nameValue);
    console.log('Valor de master: ' + master);
    if (nameValue && roomValue) {
      if (master) {
        this.router.navigate(['/client-master'], { queryParams: { room: roomValue, name: nameValue, ismaster: master } });
      } else {
        this.router.navigate(['/client'], { queryParams: { room: roomValue, name: nameValue } });
      }
    }
  }
  accesoPush(): void {
    console.log('PUSH');
    const roomValue = this.roomIdInput.nativeElement.value;
    const nameValue = this.userNameInput.nativeElement.value;
    console.log('Valor de room: ' + roomValue);
    console.log('Valor de name: ' + nameValue);
    this.router.navigate(['/push'], { queryParams: { room: roomValue, name: nameValue } });

  }
  accesoSinCamara(): void {
    console.log('Sin Camara');
    const roomValue = this.roomIdInput.nativeElement.value;
    const nameValue = this.userNameInput.nativeElement.value;
    console.log('Valor de room: ' + roomValue);
    console.log('Valor de name: ' + nameValue);
    this.router.navigate(['/nocamara'], { queryParams: { room: roomValue, name: nameValue } });
  }

  audioSinCamara(): void {
    console.log('Manda Camara y audio');
    const roomValue = this.roomIdInput.nativeElement.value;
    const nameValue = this.userNameInput.nativeElement.value;
    console.log('Valor de room: ' + roomValue);
    console.log('Valor de name: ' + nameValue);
    this.router.navigate(['/mandacamara'], { queryParams: { room: roomValue, name: nameValue } });
  }
}
<div id="miroot">
  <div id="mibody">
    <form>
      <!-- Room input -->
      <label for="room">Room</label>
      <input #roomIdInput type="text" name="room" maxlength="36" required ng-model="field1" />
      <!-- Username input -->
      <label for="username">Username</label>
      <input #userNameInput type="text" name="name" maxlength="15" required ng-model="field2"/>
      <!-- Join button -->
      <button id="joinBtn" (click)="joinRoom()">Join</button>
      <div class="checkbox-container">
        <input #masterCheckbox id="master" class="checkbox-input" type="checkbox" name="master" />
        <label for="master" class="checkbox-label">¿Eres personal sanitario?</label>
      </div>
      <div class="button-container">
      <button type="button"  class="push-btn" #pushBtn (click)="accesoPush()" >Acceso Solo Audio</button>
      <button type="button"  class="push-btn" #nocameraBtn (click)="accesoSinCamara()" >Acceso Audio Sin Cámara</button>
      </div>
      <button type="button"   #audionoCameraBtn (click)="audioSinCamara()" >Acceso Audio mandando video</button>
    </form>
  </div>
</div>
<div id="rootclient">
    <div id="bodyclient">
        <!-- Mensaje para habilitar JavaScript -->
        <noscript>You need to enable JavaScript to run this app.</noscript>

        <!-- Contenedor de carga -->
        <div id="loadingDivContainer">
            <div id="loadingDiv" class="center pulsate">
                <h1>Loading...</h1>
                <pre>
Please allow camera & microphone
access to use this app.          
                </pre>
            </div>
        </div>
        <!-- Barra de botones -->
        <div id="buttonsBar">
            <!-- Botones de control -->
            <button id="homeBtn" class="fas fa-home"></button>
        </div>
        <!-- Configuración PROBLEMA SI LO BORRO SE VA A LA B TODO -->
        <div id="settings">
            <!-- Opciones de configuración -->
            <button id="settingsCloseBtn" class="fas fa-times"></button>
            <!-- Configuración de la cámara -->
            <label for="videoSource"><i class="fas fa-video"></i> Camera</label>
            <select id="videoSource"></select>

            <!-- Configuración del micrófono -->
            <label for="audioSource"><i class="fas fa-microphone"></i> Microphone</label>
            <select id="audioSource"></select>

        </div>
    </div>
</div>
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './Componentes/home/home.component';
import { ClientmasterComponent } from './Componentes/clientmaster/clientmaster.component';
import { ClientComponent } from './Componentes/client/client.component';
import { PushToTalkComponent } from './Componentes/push-to-talk/push-to-talk.component';
import { AudioSinCamaraComponent } from './Componentes/audio-sin-camara/audio-sin-camara.component';
import { AudiogeneraCamaraComponent } from './Componentes/audiogenera-camara/audiogenera-camara.component';
export const routes: Routes = [
  { path: '', redirectTo:'/home',pathMatch:'full' },
  { path: 'home', component: HomeComponent},
  { path: 'client', component: ClientComponent },
  { path: 'client-master', component: ClientmasterComponent },
  { path: 'push', component:PushToTalkComponent},
  { path: 'nocamara', component:AudioSinCamaraComponent},
  { path: 'mandacamara', component:AudiogeneraCamaraComponent}
];


@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

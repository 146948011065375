import { Injectable } from '@angular/core';
import tippy from 'tippy.js';
@Injectable({
  providedIn: 'root'
})
export class UtilesService {
    isMobileDevice: boolean = false;
    isTabletDevice: boolean = false;
    isIPadDevice: boolean = false;
    roomURL: string = "https://example.com/room";
  
  constructor() { }
  
/** Funciones del archivo utils.js que serán de utilidad en general para el proyecto y deben de ser poder usadas por cualquier otra clase
 *@module utiles
 */

/**
 * Función para abrir una URL en una nueva pestaña o en la pantalla actual.
 * @param {string} url -  La URL a abrir.
 * @param {boolean} [blank=false] - Si abrir la URL en una nueva pestaña o no.
 */

 openURL(url:string, blank = false) {
    blank ? window.open(url, '_blank') : (window.location.href = url);
}

/**
 * Función para verificar si WebRTC está soportado por el launcher
 * @returns {boolean} Verdadero si WebRTC es soportado, si no, falso.
 */
 IsSupportedWebRTC() {
    let isWebRTCSupported = false;
    ['RTCPeerConnection', 'webkitRTCPeerConnection', 'mozRTCPeerConnection', 'RTCIceGatherer'].forEach(function (item) {
        if (isWebRTCSupported) return;
        if (item in window) {
            isWebRTCSupported = true;
        }
    });
    return isWebRTCSupported;
}

/**
 * Reproduce un sonido con el nombre específicado.
 * @param {string} name - el nombre del archivo de sonido (sin extensión).
 * @returns {Promise<boolean>} un promise que se resuelve como verdadero si el sonido se ha podido producir, si no, da lugar a un false.
 */
async playSound(name: string): Promise<boolean> {
    const sound = '../../assets/sounds/' + name + '.mp3';
    const audioToPlay = new Audio(sound);
    try {
        audioToPlay.volume = 0.5;
        await audioToPlay.play();
        return true; // Se devuelve true si se reproduce el sonido sin errores
    } catch (err) {
        return false; // Se devuelve false si ocurre un error al reproducir el sonido
    }
}


/**
 * Establece la propiedad de visualización de un elemento HTML.
 * @param {HTMLElement} element - El elemento HTML.
 * @param {boolean} display - El estado de visualización.
 * @param {string} [mode='block'] - El modo de visualización.
 * @returns {void}
 */
 elemDisplay(element: HTMLElement| null, display: boolean, mode = 'block'):void {
    if (element) element.style.display = display ? mode : 'none';
}
/**
 * Deshabilita o habilita un elemento HTML.
 * @param {HTMLInputElement} element - El elemento HTML.
 * @param {boolean} disable - El estado de deshabilitación.
 * @returns {void}
 */
 elemDisable(element: HTMLInputElement, disable: boolean): void {
    if (element) element.disabled = disable;
}
/**
 * Anima un elemento HTML utilizando animaciones CSS.
 * @param {HTMLElement} element - El elemento HTML a animar.
 * @param {string} animation - El nombre de la animación CSS.
 * @param {string} [prefix='animate__'] - El prefijo para la clase de animación.
 * @returns {Promise<string>} Una promesa que se resuelve cuando termina la animación.
 */
 animateCSS(element: HTMLElement, animation:string, prefix = 'animate__') {
    return new Promise((resolve, reject) => {
        const animationName = `${prefix}${animation}`;
        element.classList.add(`${prefix}animated`, animationName);
        function handleAnimationEnd(event: AnimationEvent) {
            event.stopPropagation();
            element.classList.remove(`${prefix}animated`, animationName);
            resolve('Animation ended');
        }
        element.addEventListener('animationend', handleAnimationEnd, { once: true });
    });
}
/**
 * Función para registrar la información de configuración de un flujo de medios.
 * @param {string} name - El nombre del flujo.
 * @param {MediaStream} stream - El flujo de medios del que se registrará la información.
 */
 logStreamSettingsInfo(name : string, stream : MediaStream) {
    if (this.hasVideoTrack(stream)) {
        console.log(name, {
            video: {
                label: stream.getVideoTracks()[0].label,
                settings: stream.getVideoTracks()[0].getSettings(),
            },
        });
    }
    if (this.hasAudioTrack(stream)) {
        console.log(name, {
            audio: {
                label: stream.getAudioTracks()[0].label,
                settings: stream.getAudioTracks()[0].getSettings(),
            },
        });
    }
}


/**
 * Función para establecer tooltips usando la biblioteca Tippy.
 * @param {HTMLElement} element - El elemento HTML al que se adjuntará el tooltip.
 * @param {string} content - El contenido del tooltip.
 * @param {string} placement - La ubicación del tooltip (por ejemplo, 'top', 'bottom', 'left', 'right').
 */
 setTippy(element: HTMLElement, content: string, placement: string) {
    if(this.isMobileDevice)return;
    if (element) {
        // Utilizamos conversión de tipo para indicar a TypeScript que el elemento puede tener la propiedad '_tippy'
        const tippyElement = element as any;
        if (tippyElement._tippy) {
            tippyElement._tippy.destroy();
        }
        tippy(element, {
            content: content,
            placement: placement,
        });
    } else {
        console.warn('setTippy element not found with content', content);
    }
}
/**
 * Función para cambiar la relación de aspecto de los elementos de video en la página.
 * @param {boolean} aspectRatio - Si se debe mantener la relación de aspecto o no.
 */
 changeAspectRatio(aspectRatio:boolean) {
    const videoElements = document.querySelectorAll('video');
    videoElements.forEach((video) => {
        video.style.objectFit = aspectRatio ? 'contain' : 'cover';
    });
}
/**
 * Función para compartir la URL de la sala usando la API de uso compartido web.
 */
async  shareRoom() {
    try {
        await navigator.share({ url: this.roomURL });
    } catch (err) {
        console.error('[Error] navigator share', err);
    }
}
/**
 * Función para manejar los eventos del cuerpo, como los movimientos del mouse.
 */
 handleBodyEvents() {
    this.checkElements();

    // Asegúrate de que buttonsBar sea de tipo HTMLElement o cualquier tipo más específico que necesites.
    const buttonsBar: HTMLElement | null = document.getElementById('buttonsBar');

    // Verifica si buttonsBar existe antes de intentar acceder a sus propiedades.
    if (buttonsBar) {
        document.body.onmousemove = () => {
            // Accede a la propiedad style de buttonsBar de manera segura
            if (buttonsBar.style.display == 'none') {
                // Llama a funciones definidas previamente
                this.toggleClassElements('videoHeader', true);
                this.elemDisplay(buttonsBar, true);
                this.animateCSS(buttonsBar, 'fadeInUp');
            }
        };
    }
}
/**
 * Verifica elementos y realiza acciones basadas en condiciones.
 * Si buttonsBar no está oculto, alterna la clase 'videoHeader',
 * anima buttonsBar para que se desvanezca y oculta el elemento buttonsBar.
 * @returns {void}
 */
 checkElements() {
    const buttonsBar: HTMLElement | null = document.getElementById('buttonsBar');
if (buttonsBar && buttonsBar.style.display !== 'none') {
        this.toggleClassElements('videoHeader', false);
        this.animateCSS(buttonsBar, 'fadeOutDown').then(() => {
            this.elemDisplay(buttonsBar, false);
        });
    }
    setTimeout(this.checkElements, 20000);
}
/**
 * Alterna la visualización de elementos con el nombre de clase especificado.
 * @param {string} className - El nombre de clase de los elementos a alternar.
 * @param {boolean} displayState - El estado de visualización para aplicar a los elementos.
 * @returns {void}
 */
 toggleClassElements(className: string, displayState: boolean) {
    // Obtener los elementos por su nombre de clase
    const elements = document.getElementsByClassName(className);

    // Iterar sobre los elementos y alternar su visualización
    for (let i = 0; i < elements.length; i++) {
        // Hacer una conversión de tipo explícita a HTMLElement
        const element = elements[i] as HTMLElement;
        this.elemDisplay(element, displayState);
    }
}

/** Funciones para verificar con qué tipo de dispositivo se está trabajando
 * @module utiles:CompruebaDispositivos
 */

/**
 * 
 * para verificar si el dispositivo es un teléfono móvil.
 * @param {string} [userAgent] - La cadena de agente de usuario del dispositivo.
 * @returns {boolean} True si el dispositivo es un teléfono móvil, de lo contrario, false.
 */


 isMobile(userAgent:string ) {
    return !!/Android|webOS|iPhone|iPad|iPod|BB10|BlackBerry|IEMobile|Opera Mini|Mobile|mobile/i.test(userAgent || '');
}
/**
 * 
 * para verificar si el dispositivo es una tablet.
 * @param {string} [userAgent] - La cadena de agente de usuario del dispositivo.
 * @returns {boolean} True si el dispositivo es una tablet, de lo contrario, false.
 */
 isTablet(userAgent:string) {
    return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
        userAgent,
    );
}
/**
 * para verificar si el dispositivo es un iPad.
 * @param {string} [userAgent] - La cadena de agente de usuario del dispositivo.
 * @returns {boolean} True si el dispositivo es un iPad, de lo contrario, false.
 */
 isIpad(userAgent:string) {
    return /macintosh/.test(userAgent) && 'ontouchend' in document;
}

/**
 * para verificar si el dispositivo es un pc.
 * @returns {boolean} True si el dispositivo es una computadora de escritorio, de lo contrario, false.
 * 
 */
 isDesktop() {
    return !this.isMobileDevice && !this.isTabletDevice && !this.isIPadDevice;
}
/** Funciones relativas a las comprobaciones de audio y video
 * @module utiles:audioYvideoCheck
 */

/**
 * Función para verificar si un flujo de medios tiene una pista de audio.
 * @param {MediaStream} mediaStream - El flujo de medios para verificar.
 * @returns {boolean} True si el flujo de medios tiene una pista de audio, de lo contrario, false.
 */
 hasAudioTrack(mediaStream:MediaStream) {
    if (!mediaStream) return false;
    const audioTracks = mediaStream.getAudioTracks();
    return audioTracks.length > 0;
}
/**
 * Función para verificar si un flujo de medios tiene una pista de video.
 * @param {MediaStream} mediaStream - El flujo de medios para verificar.
 * @returns {boolean} True si el flujo de medios tiene una pista de video, de lo contrario, false.
 */
 hasVideoTrack(mediaStream:MediaStream) {
    if (!mediaStream) return false;
    const videoTracks = mediaStream.getVideoTracks();
    return videoTracks.length > 0;
}

/**
 * Funciones relativas a la pantalla completa ( comprobaciones, iniciar modo pantalla completa, salir de este)
 * @module utiles:FullScreen 
 */

/**
 * Función para verificar si el navegador está actualmente en modo de pantalla completa.
 * @returns {boolean} True si el navegador está en modo de pantalla completa, de lo contrario, false.
 */
 isFullScreen(): boolean {
    const documentAny = document as any; // Convertir el tipo Document a any
    const elementFullScreen: Element | null =
        documentAny.fullscreenElement ||
        documentAny.webkitFullscreenElement ||
        documentAny.mozFullScreenElement ||
        documentAny.msFullscreenElement ||
        null;
    return elementFullScreen !== null;
}
/**
 * Función para verificar si el modo de pantalla completa es compatible con el navegador.
 * @returns {boolean} True si el modo de pantalla completa es compatible, de lo contrario, false.
 */
 isFullScreenSupported(): boolean {
    const documentAny = document as any; // Convertir el tipo Document a any
    return (
        documentAny.fullscreenEnabled ||
        documentAny.webkitFullscreenEnabled ||
        documentAny.mozFullScreenEnabled ||
        documentAny.msFullscreenEnabled
    );
}
/**
 * Función para solicitar el modo de pantalla completa para el elemento especificado.
 * @param {HTMLElement} element - El elemento HTML para mostrar en modo de pantalla completa.
 */
 goInFullscreen(element: HTMLElement): void {
    if (element.requestFullscreen) {
        element.requestFullscreen();
    } else if ((element as any).mozRequestFullScreen) {
        (element as any).mozRequestFullScreen();
    } else if ((element as any).webkitRequestFullscreen) {
        (element as any).webkitRequestFullscreen();
    } else if ((element as any).msRequestFullscreen) {
        (element as any).msRequestFullscreen();
    } else {
        // En caso de que ninguna de las opciones sea compatible
        console.error('Full screen mode not supported by this browser on this device.');
    }
}
/**
 * Función para salir del modo de pantalla completa.
 */
 goOutFullscreen(): void {
    const documentAny = document as any; // Convertir el tipo Document a any
    if (documentAny.exitFullscreen) documentAny.exitFullscreen();
    else if (documentAny.mozCancelFullScreen) documentAny.mozCancelFullScreen();
    else if (documentAny.webkitExitFullscreen) documentAny.webkitExitFullscreen();
    else if (documentAny.msExitFullscreen) documentAny.msExitFullscreen();
}


/**
 * Obtiene la hora actual en formato horas:minutos:segundos.
 * @returns {string} La hora actual.
 */
 getTime() {
    const date = new Date();
    return date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
}

/** Hace un elemento arrastable o lo devuelve a ser estático
 * @module utiles:elementosArrastrables
 */

/**
 * Hace un elemento HTML arrastable.
 * @param {HTMLElement} element - El elemento HTML para hacer arrastable.
 * @param {HTMLElement} [dragObj] - El elemento usado para arrastrar.
 * @returns {void}
 */
 makeDraggable(element:HTMLElement, dragObj:HTMLElement) {
    let pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
    if (dragObj) {
        dragObj.onmousedown = dragMouseDown;
    } else {
        element.onmousedown = dragMouseDown;
    }
    function dragMouseDown(e:MouseEvent) {
        e = e || window.event;
        e.preventDefault();
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        document.onmousemove = elementDrag;
    }
    function elementDrag(e:MouseEvent) {
        e = e || window.event;
        e.preventDefault();
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        element.style.top = element.offsetTop - pos2 + 'px';
        element.style.left = element.offsetLeft - pos1 + 'px';
    }
    function closeDragElement() {
        document.onmouseup = null;
        document.onmousemove = null;
    }
}
/**
 * Hace un elemento HTML no arrastable.
 * @param {HTMLElement} element - El elemento HTML para hacer no arrastable.
 * @param {HTMLElement} [dragObj] - El elemento usado para arrastrar.
 * @returns {void}
 */
 makeUnDraggable(element:HTMLElement, dragObj:HTMLElement) {
    if (dragObj) {
        dragObj.onmousedown = null;
    } else {
        element.onmousedown = null;
    }
    element.style.top = '';
    element.style.left = '';
}



}

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { popupMessage } from '../../tsApoyo/swal';
import { io } from 'socket.io-client';
import { ServerInfo } from '../../../server-info-interface';
import { RemoteConfig } from '../../../server-info-interface';
import { CandidateConfig } from '../../../server-info-interface';
import { EliminaConfig } from '../../../server-info-interface';
import { DataChannelMessage } from '../../../server-info-interface';
import { PeerStatusConfig } from '../../../server-info-interface';
import Swal from 'sweetalert2';
import { XssService } from '../../servicios/xss.service';
import { Title } from '@angular/platform-browser';
import { LocalStorage } from '../../tsApoyo/local-storage';
import { UtilesService } from '../../tsApoyo/utiles.service';
@Component({
  selector: 'app-push-to-talk',
  templateUrl: './push-to-talk.component.html',
  styleUrl: './push-to-talk.component.scss'
})
export class PushToTalkComponent implements OnInit, AfterViewInit {
  roomId: string | null;
  peerName: string | null;
  labelNombreSalaYUser: HTMLElement | null;
  localStorageConfig: any;
  LS: LocalStorage;
  userAgent!: string;
  isAudioStreaming = true;
  isWebRTCSupported = false;
  redirectURL: string = ''; // inicializadas como cadenas vacías
  surveyURL: string = ''; // inicializadas como cadenas vacías
  isMobileDevice = false;
  isTabletDevice = false;
  isIPadDevice = false;
  isDesktopDevice = false;
  activo = false;
  thisPeerId: any;
  roomURL: string | null;
  signalingSocket: any;
  localMediaStream: any;
  remoteMediaStream: any;
  roomPeersCount = 0;
  peerDevice: any = {};
  peerConnections: any = {};
  peerMediaElements: any = {};
  dataChannels: any = {};
  audioSource: HTMLSelectElement | null = null;
  currentPeerId: string | null = null;
  botonsalida: HTMLElement | null;
  tooltips: { element: HTMLElement, text: string, position: string }[] = [];

  constructor(private router: Router, private route: ActivatedRoute, private utilesService: UtilesService, private xssService: XssService, private titleService: Title) {
    this.botonsalida = document.getElementById('botonsalida');
    this.roomId = new URLSearchParams(window.location.search).get('room');
    this.peerName = new URLSearchParams(window.location.search).get('name');
    this.roomURL = window.location.origin + '/?room=' + this.roomId + '&name=' + this.peerName;
    this.LS = new LocalStorage();
    this.localStorageConfig = this.LS.getConfig() || this.LS.C2C_CONFIG;

    this.checkRoomIdAndPeerName();
    this.labelNombreSalaYUser = document.getElementById('labelSala');
    const tooltips = [
      { element: this.botonsalida, text: 'Go to home page', position: 'top' },
    ];
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const name = params['name'];
      const room = params['room'];

      console.log('Room:', room);
      console.log('Name:', name);
    });
    this.botonsalida = document.getElementById('botonsalida');
    this.audioSource = document.getElementById('audioSource') as HTMLSelectElement;
    this.titleService.setTitle('AudioSala: ' + this.roomId);
    if (!this.audioSource) {
      console.error('El elementos audioSource no fue encontrados en el DOM.');
    }
  }

  ngAfterViewInit(): void {
    this.labelNombreSalaYUser = document.getElementById('labelSala');
    if (this.labelNombreSalaYUser) {
      this.labelNombreSalaYUser.innerHTML = 'Push - Sala: ' + this.roomId + ' - Nombre: ' + this.peerName;
    }
  }

  /**
 * Envía un mensaje al servidor.
 * @param {string} msg - El mensaje que se enviará al servidor.
 * @param {Object} [config={}] - Configuración adicional para el mensaje.
 */
  async sendToServer(msg: string, config = {}) {
    await this.signalingSocket.emit(msg, config);
  }

  /**
 * Emitir el estado de un elemento a través del canal de datos.
 * @param {string} element - El elemento al que se refiere el estado.
 * @param {boolean} active - El estado activo o inactivo del elemento.
 * @category Interacción con el servidor
 */
  emitPeerStatus(element: string, active: boolean) {
    this.sendToServer('peerStatus', {
      roomId: this.roomId,
      peerName: this.peerName,
      element: element,
      active: active,
    });
  }

  /**
 * Verifica si hay conexiones de pares.
 * @returns {boolean} Devuelve true si hay conexiones de pares, de lo contrario, false.
 */
  thereIsPeerConnections() {
    if (Object.keys(this.peerConnections).length === 0) return false;
    console.log('SE HA CONECTADO CON EL PEER')
    return true;
  }


  activaPush(): void {
    if (!this.activo)
      this.initClient();
  }

  /**
   * Inicializa el cliente de la aplicación.
   */
  initClient() {
    this.activo = true;
    console.log('RoomURL', this.roomURL);
    console.log('Location', window.location);

    // Lógica de inicialización del cliente
    this.isWebRTCSupported = this.utilesService.IsSupportedWebRTC();
    if (!this.isWebRTCSupported) {
      return popupMessage('warning', 'WebRTC', 'This browser seems not supported WebRTC!');
    }

    this.userAgent = navigator.userAgent.toLowerCase();
    this.isMobileDevice = this.utilesService.isMobile(this.userAgent);
    this.isTabletDevice = this.utilesService.isTablet(this.userAgent);
    this.isIPadDevice = this.utilesService.isIpad(this.userAgent);
    this.isDesktopDevice = this.utilesService.isDesktop();

    this.tooltips.forEach(({ element, text, position }) => {
      this.utilesService.setTippy(element, text, position);
    });

    console.log('Connecting to signaling server en el puerto 5000');
    this.signalingSocket = io('https://miroserver.quantion.es', { transports: ['websocket'] });
    this.signalingSocket.on('connect', this.handleConnect.bind(this));
    this.signalingSocket.on('error', this.handleError.bind(this));
    this.signalingSocket.on('serverInfo', this.handleServerInfo.bind(this));
    this.signalingSocket.on('addPeer', this.handleAddPeer.bind(this));
    this.signalingSocket.on('sessionDescription', this.handleSessionDescription.bind(this));
    this.signalingSocket.on('iceCandidate', this.handleIceCandidate.bind(this));
    this.signalingSocket.on('peerStatus', this.handlePeerStatus.bind(this));
    this.signalingSocket.on('disconnect', this.handleDisconnect.bind(this));
    this.signalingSocket.on('removePeer', this.handleRemovePeer.bind(this));
  }

  /**
   * Adjunta un flujo de medios a un elemento HTML.
   * @param {HTMLMediaElement} element - Elemento HTML al que se adjunta el flujo de medios.
   * @param {MediaStream} stream - Flujo de medios que se adjunta al elemento.
   */
  attachMediaStream(element: HTMLMediaElement, stream: MediaStream) {
    element.srcObject = stream;
    console.log('Success, media stream attached');
  }

  /**
   * Maneja el evento de conexión con el servidor de señalización.
   */
  handleConnect = () => {
    console.log('Connected to signaling server');
    this.thisPeerId = this.signalingSocket.id;
    console.log('This peer id: ' + this.thisPeerId);
    if (this.localMediaStream) {
      console.log('EXISTE LOCALMEDIASTREAM');
      this.joinToChannel();
    } else {
      console.log('NO EXISTE LOCALMEDIASTREAM')
      this.setupLocalMedia(async () => {
        await this.enumerateDevices();
        this.loadLocalStorageConfig();
        this.joinToChannel();
      });
    }
  }

  /**
* Maneja los errores de conexión con el servidor WebSocket.
* @param {Error} error - El error de conexión.
*/
  handleError(error: Error) {
    console.error('WebSocket connection error:', error);
  }
  
  /**
* Maneja la información recibida del servidor.
* @param {ServerInfo} config - La configuración recibida del servidor.
* @param {number} config.roomPeersCount - El número de pares en la sala.
* @param {string} config.redirectURL - La URL de redireccionamiento.
* @param {string} config.surveyURL - La URL de la encuesta.
*/
  handleServerInfo(config: ServerInfo) {
    console.log('Server info', config);
    this.roomPeersCount = config.roomPeersCount;
    this.redirectURL = config.redirectURL;
    this.surveyURL = config.surveyURL;
  }


  /**
  * Maneja la adición de un par a la sala.
  * @param {Object} config - La configuración del nuevo par.
  * @param {Object[]} config.peers - Los pares existentes en la sala.
  * @param {string} config.peerId - El ID del nuevo par.
  * @param {boolean} config.shouldCreateOffer - Indica si debe crear una oferta de RTC.
  * @param {RTCIceServer[]} config.iceServers - Los servidores ICE para la conexión RTC.
  */
  handleAddPeer(config: {
    peers: any[], // Especifica el tipo de los elementos del array
    peerId: string,
    shouldCreateOffer: boolean,
    iceServers: RTCIceServer[]
  }) {


    if (this.roomPeersCount > 2) {
      return this.roomIsBusy();
    }
    const { peers, peerId, shouldCreateOffer, iceServers } = config;
    console.log('peerId:', peerId);
    console.log('peers:', peers);
    console.log('Peer añadido', peers);
    if (!peers) {
      console.warn('Peers is undefined');
      return;
    }
    if (peerId in this.peerConnections) {
      return console.warn('Peer already connected', peerId);
    }

    const peerConnection = new RTCPeerConnection({ iceServers: iceServers });
    this.peerConnections[peerId] = peerConnection;

    this.handlePeersConnectionStatus(peerId);
    this.handleOnIceCandidate(peerId);
    this.handleRTCDataChannels(peerId);
    this.handleOnTrack(peerId, peers);
    this.handleAddTracks(peerId);

    if (shouldCreateOffer) {
      this.handleRtcOffer(peerId);
    }

    console.log('Añadimos el audio a on');
    this.localMediaStream.getAudioTracks()[0].enabled = true;
    this.utilesService.playSound('soundon');
    this.currentPeerId = peerId;

    this.utilesService.playSound('join');
  }

  /**
* Maneja el evento de llegada de flujo de medios.
* @param {string} peerId - El ID del par.
* @param {Object[]} peers - Los pares existentes en la sala.
*/
  handleOnTrack(peerId: string, peers: any[]) {
    this.peerConnections[peerId].ontrack = (event: RTCTrackEvent) => {
      console.log('Handle on track event', event);
      if (event.track.kind === 'audio') { // Solo manejar flujos de audio
        this.setRemoteMedia(event.streams[0], peers, peerId);
      }
    };
  }

  /**
  * Maneja la recepción de un candidato ICE del par.
  * @param {string} peerId - El ID del par.
  */
  handleOnIceCandidate(peerId: string) {
    this.peerConnections[peerId].onicecandidate = (event: RTCPeerConnectionIceEvent) => {
      if (!event.candidate) return;
      this.sendToServer('relayICE', {
        peerId: peerId,
        iceCandidate: {
          sdpMLineIndex: event.candidate.sdpMLineIndex,
          candidate: event.candidate.candidate,
        },
      });
    };
  }

  /**
  * Maneja el estado de conexión de los pares.
  * @param {string} peerId - El ID del par.
  */
  handlePeersConnectionStatus(peerId: string) {
    this.peerConnections[peerId].onconnectionstatechange = (event: Event) => {
      const connectionStatus = (event.target as RTCPeerConnection).connectionState;
      console.log('Connection', { peerId: peerId, connectionStatus: connectionStatus });
    };
  }

  /**
   * Añade las pistas locales a la conexión RTC con el par.
   * @param {string} peerId - El ID del par.
   */
  handleAddTracks(peerId: string) {
    this.localMediaStream.getTracks().forEach((track: MediaStreamTrack) => {
      console.warn('Track:', track);
      this.peerConnections[peerId].addTrack(track, this.localMediaStream);
    });
  }

  /**
* Maneja los canales de datos RTC para un par específico.
* @param {string} peerId - El ID del par.
* @returns {Promise<void>} Una promesa que se resuelve una vez que se hayan configurado los canales de datos.
*/
  async handleRTCDataChannels(peerId: string) {
    this.peerConnections[peerId].ondatachannel = (event: RTCDataChannelEvent) => {
      console.log('Datachannel event peerId: ' + peerId, event);
      event.channel.onmessage = (msg) => {
        try {
          const config: DataChannelMessage = JSON.parse(this.xssService.filter(msg.data));
          this.handleIncomingDataChannelMessage(config);
        } catch (err) {
          console.log('Datachannel error', err);
        }
      };
    };

    this.dataChannels[peerId] = this.peerConnections[peerId].createDataChannel('mt_c2c_dc');
    this.dataChannels[peerId].onopen = (event: Event) => {
      console.log('DataChannels created for peerId: ' + peerId, event);
    };
  }


/**
* Maneja la oferta RTC para un par específico.
* @param {string} peerId - El ID del par.
*/
  handleRtcOffer(peerId: string) {
    this.peerConnections[peerId].onnegotiationneeded = () => {
      console.log('Creating RTC offer to', peerId);
      this.peerConnections[peerId]
        .createOffer()
        .then((localDescription: RTCSessionDescriptionInit) => {
          console.log('Local offer description is', localDescription);
          this.peerConnections[peerId]
            .setLocalDescription(localDescription)
            .then(() => {
              this.sendToServer('relaySDP', {
                peerId: peerId,
                sessionDescription: localDescription,
              });
              console.log('Offer setLocalDescription done!');
            })
            .catch((err: Error) => {
              console.error('[Error] offer setLocalDescription', err);
            });
        })
        .catch((err: Error) => {
          console.error('[Error] sending offer', err);
        });
    };
  }

  /**
  * Maneja la descripción de sesión remota.
  * @param {Object} config - La configuración de la descripción de sesión remota.
  * @param {string} config.peerId - El ID del par.
  * @param {RTCSessionDescriptionInit} config.sessionDescription - La descripción de sesión remota.
  */
  handleSessionDescription(config: RemoteConfig) {
    console.log('Remote Session Description', config);
    const { peerId, sessionDescription } = config;
    const remoteDescription = new RTCSessionDescription(sessionDescription);
    this.peerConnections[peerId]
      .setRemoteDescription(remoteDescription)
      .then(() => {
        console.log('Set remote description done!');
        if (sessionDescription.type == 'offer') {
          console.log('Creating answer');
          this.peerConnections[peerId]
            .createAnswer()
            .then((localDescription: RTCSessionDescriptionInit) => {
              console.log('Answer description is: ', localDescription);
              this.peerConnections[peerId]
                .setLocalDescription(localDescription)
                .then(() => {
                  this.sendToServer('relaySDP', {
                    peerId: peerId,
                    sessionDescription: localDescription,
                  });
                  console.log('Answer setLocalDescription done!');
                })
                .catch((err: Error) => {
                  console.error('[Error] answer setLocalDescription', err);
                });
            })
            .catch((err: Error) => {
              console.error('[Error] creating answer', err);
            });
        }
      })
      .catch((err: Error) => {
        console.error('[Error] setRemoteDescription', err);
      });
  }

  /**
* Maneja un candidato ICE para añadirlo para establecer la conexión entre pares.
* @param {Object} config - La configuración del candidato ICE.
* @param {string} config.peerId - El ID del par.
* @param {RTCIceCandidateInit} config.iceCandidate - El candidato ICE.
*/
  handleIceCandidate(config: CandidateConfig) {
    const { peerId, iceCandidate } = config;
    this.peerConnections[peerId].addIceCandidate(new RTCIceCandidate(iceCandidate)).catch((err: Error) => {
      console.error('[Error] addIceCandidate', err);
    });
  }

  /**
* Maneja el estado de un par remoto en función del elemento especificado.
* @param {Object} config - La configuración del estado del par remoto.
* @param {string} config.element - El elemento al que se refiere el estado ('video' o'audio' ).
* @param {string} config.peerId - El identificador del par remoto.
* @param {boolean} config.active - El estado activo o inactivo del elemento.
* @category Interacción con los pares remotos
*/
  handlePeerStatus(config: PeerStatusConfig) {
    const { element, peerId, active } = config;
    switch (element) {
      case 'audio':
        this.setPeerAudioStatus(peerId, active);
        break;
    }
  }

/**
* Maneja los mensajes entrantes del canal de datos.
* @param {Object} config - Configuración del mensaje entrante.
*/
  handleIncomingDataChannelMessage(config: DataChannelMessage) {
    switch (config.type) {
      default:
        break;
    }
  }

  handleDisconnect() {
    console.log('Disconnected from signaling server');
    this.utilesService.playSound('colgar');
    for (let peerId in this.peerMediaElements) {
      const peerMediaElement = this.peerMediaElements[peerId];
      if (peerMediaElement.parentNode) {
        peerMediaElement.parentNode.removeChild(peerMediaElement);
      }
    }
    for (let peerId in this.peerConnections) {
      this.peerConnections[peerId].close();
    }
    this.peerConnections = {};
    this.peerMediaElements = {};
    this.dataChannels = {};

    const clientComponentInstance = this;
    clientComponentInstance.saveLocalStorageConfig();
  }

  /**
 * Maneja la eliminación de un par de la sala.
 * @param {Object} config - La configuración de la eliminación del par.
 * @param {string} config.peerId - El ID del par.
 */
  handleRemovePeer(config: EliminaConfig) {
    console.log('Signaling server said to remove peer:', config);
    // Desactivamos el audio y video
    console.log('Añadimos el audio a off');
    this.localMediaStream.getAudioTracks()[0].enabled = false;
    this.utilesService.playSound('soundoff');
    const { peerId } = config;

    const container = document.getElementById('bodyclient'); // Obtener el contenedor
    if (container && peerId in this.peerMediaElements && this.peerMediaElements[peerId].parentNode) {
      container.removeChild(this.peerMediaElements[peerId].parentNode);
    } else {
      console.error('Container not found or peer element not in DOM');
    }

    if (peerId in this.peerConnections) this.peerConnections[peerId].close();

    this.currentPeerId = null;

    delete this.dataChannels[peerId];
    delete this.peerConnections[peerId];
    delete this.peerMediaElements[peerId];

    console.log('Peers count: ' + Object.keys(this.peerConnections).length);
    this.utilesService.playSound('leave');
  }


  roomIsBusy() {
    this.signalingSocket.disconnect();
    Swal.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      position: 'center',
      icon: 'info',
      title: 'Room is busy',
      text: 'Please try with another one',
      showDenyButton: false,
      confirmButtonText: `OK`,
      showClass: { popup: 'animate__animated animate__fadeInDown' },
      hideClass: { popup: 'animate__animated animate__fadeOutUp' },
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/'], {});
      }
    });
  }

/**
* Se une al canal de comunicación.
*/
  joinToChannel() {
    console.log('Join to channel', this.roomId);
    this.sendToServer('join', {
      channel: this.roomId,
      peerInfo: {
        peerName: this.peerName,
        peerAudio: this.isAudioStreaming,
      },
      peerDevice: {
        userAgent: this.userAgent,
        isWebRTCSupported: this.isWebRTCSupported,
        isMobileDevice: this.isMobileDevice,
        isTabletDevice: this.isTabletDevice,
        isIPadDevice: this.isIPadDevice,
        isDesktopDevice: this.isDesktopDevice,
      },
    });
    this.utilesService.playSound('join');
  }

  /**
 * Agrega un hijo (opción) a un elemento select.
 * @param {HTMLElement} source - El elemento select al que se agregará el hijo.
 * @param {MediaDeviceInfo} device - El dispositivo de audio o video.
 * @returns {Promise<void>} Una promesa que se resuelve después de agregar el hijo.
 */
  async addChild(source: HTMLSelectElement | null, device: MediaDeviceInfo) {
    if (!source) {
      console.error('Error: source is undefined');
      return;
    }
    const option = document.createElement('option');
    option.value = device.deviceId;
    option.text = device.label;
    source.appendChild(option);
  }

  /**
   * Realiza el fin de la conexión
   * 
   */
  salida(): void {
    console.log('Prueba salida')
    PushToTalkComponent.prototype.saveLocalStorageConfig.call(this);
    if (this.signalingSocket)
      this.signalingSocket.disconnect();
    this.router.navigate(['/home']);

  }


  /**
   * Se chequea si la sala y el nombre existen
   */
  private checkRoomIdAndPeerName() {
    if (this.roomId == null || this.peerName == null) {
      this.router.navigate(['/'], {});
    }
    console.log("El nombre y sala son correctos");
  }



  /**
 * Establece el estado de audio del par remoto.
 * @param {string} peerId - El identificador del par remoto.
 * @param {boolean} active - El estado activo o inactivo del audio.
 * @category Interacción con los pares remotos
 */
  setPeerAudioStatus(peerId: string, active: boolean) {
    console.log(`Remote PeerId ${peerId} audio status`, active);
    const peerAudioStatus = document.getElementById(peerId + '_remoteAudioStatus');
  }
/**
 * Función para enumerar los dispositivos accesible para la aplicación
 */
  async enumerateDevices() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioDevices = devices.filter(device => device.kind === 'audioinput' && device.deviceId !== 'default');

      console.log('Devices', {
        audioDevices: audioDevices,
      });

      for (const device of audioDevices) {
        console.log('audioSource:', this.audioSource);
        await this.addChild(this.audioSource, device);
        console.log('El número del dispositivo actual de audio es: ' + this.LS.DEVICES_COUNT.audio);
        this.LS.DEVICES_COUNT.audio++;
      }
    } catch (err) {
      this.utilesService.playSound('error');
      console.error('[Error] enumerate devices audio/video', err);
      popupMessage('error', 'Enumerate Devices', 'Unable to enumerate devices ' + err);
    }
  }

  /**
     * Configura los medios locales (audio y video).
     * @param {Function} callback - Función de devolución de llamada que se llama cuando se han configurado los medios locales con éxito.
     * @param {Function} [errorBack] - Función de devolución de llamada que se llama en caso de error al configurar los medios locales.
     */
  async setupLocalMedia(callback: () => void, errorBack?: (error: Error) => void) {
    if (this.localMediaStream) {
      if (callback) callback();
      return;
    }

    try {
      const audioDeviceId = this.localStorageConfig.audio.devices.select ? this.localStorageConfig.audio.devices.select.value : undefined;



      const audioConstraints: MediaTrackConstraints = typeof audioDeviceId === 'string' ? { deviceId: { exact: audioDeviceId } } : audioDeviceId ? { deviceId: { exact: undefined } } : {};
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: audioConstraints,
      });
      stream.getAudioTracks()[0].enabled = false;
      this.setLocalMedia(stream);
      if (callback) callback();
    } catch (err) {
      this.utilesService.playSound('error');
      console.error('[Error] access denied for audio/video', err);

      // Manejo del error si se proporciona una función de devolución de llamada de error
      if (errorBack) {
        errorBack(err as Error);
      } else {
        console.warn('No error callback provided for setupLocalMedia');
      }

      // Muestra un mensaje al usuario informando que no se pueden utilizar cámara ni micrófono
      popupMessage(
        'info',
        'GetUserMedia',
        `<p>You have joined the call without camera or microphone access.</p>`
      );
      if (callback) callback();
    }
  }
  /**
   * Se crean las funciones necesarias para tomar correctamente el flujo remoto
   * @param stream 
   * @param peers 
   * @param peerId 
   * @returns 
   */

  setRemoteMedia(stream: MediaStream, peers: { [key: string]: any }, peerId: string) {
    this.remoteMediaStream = stream;
    if (!(peerId in peers)) {
      console.warn('Peer not found', peerId);
      return;
    }

    const peerInfo = peers[peerId];
    const peerName = peerInfo.peerName;
    const peerAudio = peerInfo.peerAudio;

    const remoteMedia = document.createElement('audio');
    remoteMedia.id = peerId + '_remoteAudio';
    remoteMedia.autoplay = true;
    remoteMedia.controls = false;

    document.body.appendChild(remoteMedia);

    this.attachMediaStream(remoteMedia, this.remoteMediaStream);

    this.setPeerAudioStatus(peerId, peerAudio);

    const container = document.getElementById('bodyclient');
    if (container) {
      container.appendChild(remoteMedia);
    } else {
      console.error('Container not found');
    }
  }

  /**
     * Establece los medios locales (audio/video) y crea los elementos necesarios para mostrarlos.
     * @param {MediaStream} stream - El flujo de medios local.
     */
  setLocalMedia(stream: MediaStream) {
    console.log('Access granted to audio/video');
    this.localMediaStream = stream;
  }


  /**
   * Obtiene las restricciones para la captura de audio.
   * @param {string|boolean} deviceId - ID del dispositivo de audio o false para utilizar el dispositivo predeterminado.
   * @returns {Object|boolean} Restricciones de audio o true para utilizar el dispositivo predeterminado.
   */
  getAudioConstraints(deviceId = false) {
    if (deviceId) return { deviceId: deviceId };
    return true;
  }

  /**
 * Guarda la configuración actual en el almacenamiento local.
 */
  saveLocalStorageConfig() {
    this.localStorageConfig.audio.devices.count = this.LS.DEVICES_COUNT.audio;
    this.LS.setConfig(this.localStorageConfig);
  }

  /**
* Carga la configuración previa desde el almacenamiento local.
*/
  loadLocalStorageConfig() {
    if (this.audioSource) {
      this.audioSource.selectedIndex = this.localStorageConfig.audio.devices.select.index;
    } else {
      console.error('audioSource nulo.');
    }
  }
}




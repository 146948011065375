import { Injectable } from '@angular/core';
import { filterXSS } from 'xss';
@Injectable({
  providedIn: 'root'
})
export class XssService {

  constructor() { }
  filter(content: string): string {
    return filterXSS(content);
  }
}

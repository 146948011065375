import { Component, OnInit, AfterViewInit } from '@angular/core';
import { LocalStorage } from '../../tsApoyo/local-storage';
import { UtilesService } from '../../tsApoyo/utiles.service';
import { Router } from '@angular/router';
import { popupMessage } from '../../tsApoyo/swal';
import { io } from 'socket.io-client';
import { ServerInfo } from '../../../server-info-interface';
import { RemoteConfig } from '../../../server-info-interface';
import { CandidateConfig } from '../../../server-info-interface';
import { EliminaConfig } from '../../../server-info-interface';
import { DataChannelMessage } from '../../../server-info-interface';
import { PeerStatusConfig } from '../../../server-info-interface';
import { Title } from '@angular/platform-browser';


import Swal from 'sweetalert2';
import { XssService } from '../../servicios/xss.service';

@Component({
  selector: 'app-audio-sin-camara',
  templateUrl: './audio-sin-camara.component.html',
  styleUrls: ['../../client.scss']
})
export class AudioSinCamaraComponent implements OnInit, AfterViewInit {
  roomId: string | null;
  master: string | null;
  peerName: string | null;
  loadingDivContainer: HTMLElement | null;
  labelNombreSalaYUser: HTMLElement | null;
  buttonsBar: HTMLElement | null;
  audioBtn: HTMLElement | null;
  homeBtn: HTMLElement | null;
  roomURL: string | null;
  actdesavclientBtn: HTMLElement | null;
  apagaclientBtn: HTMLElement | null;
  LS: LocalStorage;
  localStorageConfig: any;
  redirectURL: string = ''; // inicializadas como cadenas vacías
  surveyURL: string = ''; // inicializadas como cadenas vacías
  image = {
    camOff: '../../assets/camOff.png',
    feedback: '../../assets/feedback.png',
  };

  className = {
    user: 'fas fa-user',
    userOff: 'fas fa-user-slash',
    audioOn: 'fas fa-microphone',
    audioOff: 'fas fa-microphone-slash',
    draggable: 'fas fa-up-down-left-right',
    fullScreenOn: 'fas fa-expand',
    fullScreenOff: 'fas fa-compress',
    pip: 'fas fa-images',
    rotate: 'fas fa-rotate-right',
  };

  swal = {
    background: '#202123',
    textColor: '#ffffff',
  };


  userAgent!: string;
  isWebRTCSupported = false;
  isMobileDevice = false;
  isTabletDevice = false;
  isIPadDevice = false;
  isDesktopDevice = false;
  isCamMirrored = false;
  myVideoChange = false;
  isVideoStreaming = true;
  isAudioStreaming = true;
  isSpaceDown = false;
  isMyVideoActiveBefore = false;
  camera = 'user';
  thisPeerId: any;
  signalingSocket: any;
  localMediaStream: any;

  remoteMediaStream: any;
  roomPeersCount = 0;
  peerDevice: any = {};
  peerConnections: any = {};
  peerMediaElements: any = {};
  dataChannels: any = {};
  audioSource: HTMLSelectElement | null = null;
  currentPeerId: string | null = null;

  myAudio:any

  myVideo: any;
  myVideoAvatarImage: any;
  myAudioStatusIcon: any;


  tooltips: { element: HTMLElement, text: string, position: string }[] = [];


  constructor(private router: Router, private utilesService: UtilesService, private xssService: XssService,private titleService: Title) {
    this.roomId = new URLSearchParams(window.location.search).get('room');
    this.peerName = new URLSearchParams(window.location.search).get('name');
    this.checkRoomIdAndPeerName();
    this.loadingDivContainer = document.getElementById('loadingDivContainer');
    this.buttonsBar = document.getElementById('buttonsBar');
    this.audioBtn = document.getElementById('audioBtn');
    this.homeBtn = document.getElementById('homeBtn');
    this.labelNombreSalaYUser=document.getElementById('masterModeLabel');
    this.actdesavclientBtn = document.getElementById('clientCmrBtn');
    this.apagaclientBtn = document.getElementById('salidaclienteBtn');
    console.log('LA URL ES: ' + this.roomURL);
    this.LS = new LocalStorage();
    this.localStorageConfig = this.LS.getConfig() || this.LS.C2C_CONFIG;
    console.log('LS:', this.LS);
    console.log('localStorageConfig:', this.localStorageConfig);
    console.log('typeof saveLocalStorageConfig:', typeof this.saveLocalStorageConfig);
    window.addEventListener('load', this.handleWindowLoad);
    // Se registra el evento de salida de la página.
    window.onbeforeunload = (event: Event) => this.confirmExit(event);
    console.log('Local Storage Config', this.localStorageConfig);
    /**
  * Lista de objetos que contienen información sobre las herramientas de ayuda (tooltips).
  * @type {Object[]}
  */
    const tooltips = [
      { element: this.audioBtn, text: 'Toggle audio', position: 'top' },
      { element: this.homeBtn, text: 'Go to home page', position: 'top' },
      { element: this.actdesavclientBtn, text: 'Toggle both cameras', position: 'top' },
      { element: this.apagaclientBtn, text: 'Disconnect yourself and the client', position: 'top' },
    ];
    console.log('Se termina el constructor de clientmaster');
  }
  private checkRoomIdAndPeerName() {
    if (this.roomId == null || this.peerName == null) {
      this.router.navigate(['/'], {});
    }
    console.log("El nombre y sala son correctos");
  }
  ngOnInit(): void {
    console.log("Iniciamos el cliente-master");
    // Método de inicialización
    this.peerConnections = {};
    this.audioSource = document.getElementById('audioSource') as HTMLSelectElement;
    this.loadingDivContainer = document.getElementById('loadingDivContainer');
    this.buttonsBar = document.getElementById('buttonsBar');
    this.audioBtn = document.getElementById('audioBtn');
    this.homeBtn = document.getElementById('homeBtn');
    this.actdesavclientBtn = document.getElementById('clientCmrBtn');
    this.apagaclientBtn = document.getElementById('salidaclienteBtn');
    this.labelNombreSalaYUser=document.getElementById('masterModeLabel');
    this.titleService.setTitle('Sala ' + this.roomId + ' Control Enfermería');
    if (this.labelNombreSalaYUser) {
      this.labelNombreSalaYUser.innerHTML = '<i class="fas fa-crown"></i> Enfermería | Sala:' + this.roomId;
    }
    if (!this.audioSource) {
      console.error('AudioSource no fue encontrado en el DOM.');
    }

    this.initClient();
  }

  
  ngAfterViewInit(): void {
    this.labelNombreSalaYUser = document.getElementById('inicioLabel');
    if (this.labelNombreSalaYUser) {

      this.labelNombreSalaYUser.innerHTML = '<i class="fas fa-crown"></i> Enfermería | Sala:' + this.roomId;
    }
  }


  /**
   * Verifica si hay conexiones de pares.
   * @returns {boolean} Devuelve true si hay conexiones de pares, de lo contrario, false.
   */
  thereIsPeerConnections() {
    if (Object.keys(this.peerConnections).length === 0) return false;
    console.log('SE HA CONECTADO CON EL PEER')
    return true;
  }

  /**
   * Inicializa el cliente de la aplicación.
   */
  initClient() {

    console.log('RoomURL', this.roomURL);
    console.log('Location', window.location);

    // Lógica de inicialización del cliente
    this.isWebRTCSupported = this.utilesService.IsSupportedWebRTC();
    if (!this.isWebRTCSupported) {
      return popupMessage('warning', 'WebRTC', 'This browser seems not supported WebRTC!');
    }

    this.userAgent = navigator.userAgent.toLowerCase();
    this.isMobileDevice = this.utilesService.isMobile(this.userAgent);
    this.isTabletDevice = this.utilesService.isTablet(this.userAgent);
    this.isIPadDevice = this.utilesService.isIpad(this.userAgent);
    this.isDesktopDevice = this.utilesService.isDesktop();

    this.tooltips.forEach(({ element, text, position }) => {
      this.utilesService.setTippy(element, text, position);
    });

    console.log('Connecting to signaling server en el puerto 5000');
    this.signalingSocket = io('https://miroserver.quantion.es', { transports: ['websocket'] });
    this.signalingSocket.on('connect', this.handleConnect.bind(this));
    this.signalingSocket.on('error', this.handleError.bind(this));
    this.signalingSocket.on('serverInfo', this.handleServerInfo.bind(this));
    this.signalingSocket.on('addPeer', this.handleAddPeer.bind(this));
    this.signalingSocket.on('sessionDescription', this.handleSessionDescription.bind(this));
    this.signalingSocket.on('iceCandidate', this.handleIceCandidate.bind(this));
    this.signalingSocket.on('peerStatus', this.handlePeerStatus.bind(this));
    this.signalingSocket.on('disconnect', this.handleDisconnect.bind(this));
    this.signalingSocket.on('removePeer', this.handleRemovePeer.bind(this));
  }

  /**
   * Se une al canal de comunicación.
   */
  joinToChannel() {
    console.log('Join to channel', this.roomId);
    this.sendToServer('join', {
      channel: this.roomId,
      peerInfo: {
        peerName: this.peerName,
        peerAudio: this.isAudioStreaming,
      },
      peerDevice: {
        userAgent: this.userAgent,
        isWebRTCSupported: this.isWebRTCSupported,
        isMobileDevice: this.isMobileDevice,
        isTabletDevice: this.isTabletDevice,
        isIPadDevice: this.isIPadDevice,
        isDesktopDevice: this.isDesktopDevice,
      },
    });
    this.utilesService.playSound('join');
  }

  roomIsBusy() {
    this.signalingSocket.disconnect();
    Swal.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      position: 'center',
      icon: 'info',
      title: 'Room is busy',
      text: 'Please try with another one',
      showDenyButton: false,
      confirmButtonText: `OK`,
      showClass: { popup: 'animate__animated animate__fadeInDown' },
      hideClass: { popup: 'animate__animated animate__fadeOutUp' },
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/'], {});
      }
    });
  }

  /**
   * Adjunta un flujo de medios a un elemento HTML.
   * @param {HTMLMediaElement} element - Elemento HTML al que se adjunta el flujo de medios.
   * @param {MediaStream} stream - Flujo de medios que se adjunta al elemento.
   */
  attachMediaStream(element: HTMLMediaElement, stream: MediaStream) {
    element.srcObject = stream;
    console.log('Success, media stream attached');
  }

  /**
   * Envía un mensaje al servidor.
   * @param {string} msg - El mensaje que se enviará al servidor.
   * @param {Object} [config={}] - Configuración adicional para el mensaje.
   */
  async sendToServer(msg: string, config = {}) {
    await this.signalingSocket.emit(msg, config);
  }

  /**
   * Función para enumerar los dispositivos accesible para la aplicación
   */
  async enumerateDevices() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioDevices = devices.filter(device => device.kind === 'audioinput' && device.deviceId !== 'default');

      console.log('Devices', {
        audioDevices: audioDevices,
      });

      for (const device of audioDevices) {
        console.log('audioSource:', this.audioSource);
        await this.addChild(this.audioSource, device);
        console.log('El número del dispositivo actual de audio es: ' + this.LS.DEVICES_COUNT.audio);
        this.LS.DEVICES_COUNT.audio++;
      }
    } catch (err) {
      this.utilesService.playSound('error');
      console.error('[Error] enumerate devices audio', err);
      popupMessage('error', 'Enumerate Devices', 'Unable to enumerate devices ' + err);
    }
  }

  /**
   * Agrega un hijo (opción) a un elemento select.
   * @param {HTMLElement} source - El elemento select al que se agregará el hijo.
   * @param {MediaDeviceInfo} device - El dispositivo de audio o video.
   * @returns {Promise<void>} Una promesa que se resuelve después de agregar el hijo.
   */
  async addChild(source: HTMLSelectElement | null, device: MediaDeviceInfo) {
    if (!source) {
      console.error('Error: source is undefined');
      return;
    }
    const option = document.createElement('option');
    option.value = device.deviceId;
    option.text = device.label;
    source.appendChild(option);
  }
  /**
   * Muestra al usuario en espera ocultando el contenedor de carga y mostrando el contenedor de espera.
   */
  cambiaEspera() {
    console.log('Muestrame la barra de botones');
    this.utilesService.elemDisplay(this.loadingDivContainer, false);
    this.utilesService.elemDisplay(this.buttonsBar, true);
  }

  /**
   * Emitir el estado de un elemento a través del canal de datos.
   * @param {string} element - El elemento al que se refiere el estado.
   * @param {boolean} active - El estado activo o inactivo del elemento.
   * @category Interacción con el servidor
   */
  emitPeerStatus(element: string, active: boolean) {
    this.sendToServer('peerStatus', {
      roomId: this.roomId,
      peerName: this.peerName,
      element: element,
      active: active,
    });
  }

  /**
   * Restablece el zoom de todos los elementos de video en la página.
   * @category Interacción con la IU
   */
  resetVideoZoom() {
    const videoElements = document.querySelectorAll('video');
    videoElements.forEach((video) => {
      video.style.transform = '';
      video.style.transformOrigin = 'center';
    });
  }

  /**
   * Finaliza la llamada guardando la configuración en el almacenamiento local y desconectándose del servidor de señalización.
   */
  endCall() {
    
    AudioSinCamaraComponent.prototype.saveLocalStorageConfig.call(this);
    this.signalingSocket.disconnect();
    this.router.navigate(['/home']);

  }

  /**
   * Redirige a la URL de redirección si está definida, de lo contrario, redirige a la página principal.
   */
  redirectOnLeave() {
    this.redirectURL ? this.utilesService.openURL(this.redirectURL) : this.utilesService.openURL('/');
  }


  /**
   * @module client:manejadores
   */

  /**
   * Maneja el evento de conexión con el servidor de señalización.
   */
  handleConnect = () => {
    console.log('Connected to signaling server');
    this.thisPeerId = this.signalingSocket.id;
    console.log('This peer id: ' + this.thisPeerId);
    if (this.localMediaStream) {
      console.log('EXISTE LOCALMEDIASTREAM');
      this.joinToChannel();
    } else {
      console.log('NO EXISTE LOCALMEDIASTREAM')
      this.setupLocalMedia(async () => {
        await this.enumerateDevices();
        this.handleEvents();
        this.loadLocalStorageConfig();
        this.joinToChannel();
        this.cambiaEspera();
      });
    }
  }
  /**
  * Maneja los errores de conexión con el servidor WebSocket.
  * @param {Error} error - El error de conexión.
  */
  handleError(error: Error) {
    console.error('WebSocket connection error:', error);
  }

  /**
  * Maneja la información recibida del servidor.
  * @param {ServerInfo} config - La configuración recibida del servidor.
  * @param {number} config.roomPeersCount - El número de pares en la sala.
  * @param {string} config.redirectURL - La URL de redireccionamiento.
  * @param {string} config.surveyURL - La URL de la encuesta.
  */
  handleServerInfo(config: ServerInfo) {
    console.log('Server info', config);
    this.roomPeersCount = config.roomPeersCount;
    this.redirectURL = config.redirectURL;
    this.surveyURL = config.surveyURL;
  }
  /**
  * Maneja la adición de un par a la sala.
  * @param {Object} config - La configuración del nuevo par.
  * @param {Object[]} config.peers - Los pares existentes en la sala.
  * @param {string} config.peerId - El ID del nuevo par.
  * @param {boolean} config.shouldCreateOffer - Indica si debe crear una oferta de RTC.
  * @param {RTCIceServer[]} config.iceServers - Los servidores ICE para la conexión RTC.
  */
  handleAddPeer(config: {
    peers: any[], // Especifica el tipo de los elementos del array
    peerId: string,
    shouldCreateOffer: boolean,
    iceServers: RTCIceServer[]
  }) {


    if (this.roomPeersCount > 2) {
      return this.roomIsBusy();
    }
    const { peers, peerId, shouldCreateOffer, iceServers } = config;
    console.log('peerId:', peerId);
    console.log('peers:', peers);
    console.log('Peer añadido', peers);
    if (!peers) {
      console.warn('Peers is undefined');
      return;
    }
    if (peerId in this.peerConnections) {
      return console.warn('Peer already connected', peerId);
    }
    if (this.buttonsBar) {
      this.utilesService.elemDisplay(this.buttonsBar, true);
      this.utilesService.animateCSS(this.buttonsBar, 'fadeInUp');
    }
    const peerConnection = new RTCPeerConnection({ iceServers: iceServers });
    this.peerConnections[peerId] = peerConnection;

    this.handlePeersConnectionStatus(peerId);
    this.handleOnIceCandidate(peerId);
    this.handleRTCDataChannels(peerId);
    this.handleOnTrack(peerId, peers);
    this.handleAddTracks(peerId);

    if (shouldCreateOffer) {
      this.handleRtcOffer(peerId);
    }

    //Reactivamos el audio
    console.log('Añadimos el audio a on');
    if (this.audioBtn)
      this.audioBtn.className = this.className.audioOn;
    this.localMediaStream.getAudioTracks()[0].enabled = true;
    this.utilesService.playSound('soundon');
    this.currentPeerId = peerId;

    this.utilesService.playSound('join');
  }
  /**
  * Maneja el estado de conexión de los pares.
  * @param {string} peerId - El ID del par.
  */
  handlePeersConnectionStatus(peerId: string) {
    this.peerConnections[peerId].onconnectionstatechange = (event: Event) => {
      const connectionStatus = (event.target as RTCPeerConnection).connectionState;
      console.log('Connection', { peerId: peerId, connectionStatus: connectionStatus });
    };
  }



  /**
  * Maneja la recepción de un candidato ICE del par.
  * @param {string} peerId - El ID del par.
  */
  handleOnIceCandidate(peerId: string) {
    this.peerConnections[peerId].onicecandidate = (event: RTCPeerConnectionIceEvent) => {
      if (!event.candidate) return;
      this.sendToServer('relayICE', {
        peerId: peerId,
        iceCandidate: {
          sdpMLineIndex: event.candidate.sdpMLineIndex,
          candidate: event.candidate.candidate,
        },
      });
    };
  }
  /**
  * Maneja los canales de datos RTC para un par específico.
  * @param {string} peerId - El ID del par.
  * @returns {Promise<void>} Una promesa que se resuelve una vez que se hayan configurado los canales de datos.
  */

  async handleRTCDataChannels(peerId: string) {
    this.peerConnections[peerId].ondatachannel = (event: RTCDataChannelEvent) => {
      console.log('Datachannel event peerId: ' + peerId, event);
      event.channel.onmessage = (msg) => {
        try {
          const config: DataChannelMessage = JSON.parse(this.xssService.filter(msg.data));
          this.handleIncomingDataChannelMessage(config);
        } catch (err) {
          console.log('Datachannel error', err);
        }
      };
    };

    this.dataChannels[peerId] = this.peerConnections[peerId].createDataChannel('mt_c2c_dc');
    this.dataChannels[peerId].onopen = (event: Event) => {
      console.log('DataChannels created for peerId: ' + peerId, event);
    };
  }
  /**
  * Maneja el evento de llegada de flujo de medios.
  * @param {string} peerId - El ID del par.
  * @param {Object[]} peers - Los pares existentes en la sala.
  */
  handleOnTrack(peerId: string, peers: any[]) {
    this.peerConnections[peerId].ontrack = (event: RTCTrackEvent) => {
      console.log('Handle on track event', event);
      if (event.track.kind === 'video') {
        this.setRemoteMedia(event.streams[0], peers, peerId);
      }
    };
  }
  /**
   * Añade las pistas locales a la conexión RTC con el par.
   * @param {string} peerId - El ID del par.
   */
  handleAddTracks(peerId: string) {
    this.localMediaStream.getTracks().forEach((track: MediaStreamTrack) => {
      console.warn('Track:', track);
      
      this.peerConnections[peerId].addTrack(track, this.localMediaStream);
    });
  }
  /**
  * Maneja la oferta RTC para un par específico.
  * @param {string} peerId - El ID del par.
  */
  handleRtcOffer(peerId: string) {
    this.peerConnections[peerId].onnegotiationneeded = () => {
      console.log('Creating RTC offer to', peerId);
      this.peerConnections[peerId]
        .createOffer()
        .then((localDescription: RTCSessionDescriptionInit) => {
          console.log('Local offer description is', localDescription);
          this.peerConnections[peerId]
            .setLocalDescription(localDescription)
            .then(() => {
              this.sendToServer('relaySDP', {
                peerId: peerId,
                sessionDescription: localDescription,
              });
              console.log('Offer setLocalDescription done!');
            })
            .catch((err: Error) => {
              console.error('[Error] offer setLocalDescription', err);
            });
        })
        .catch((err: Error) => {
          console.error('[Error] sending offer', err);
        });
    };
  }
  /**
  * Maneja la descripción de sesión remota.
  * @param {Object} config - La configuración de la descripción de sesión remota.
  * @param {string} config.peerId - El ID del par.
  * @param {RTCSessionDescriptionInit} config.sessionDescription - La descripción de sesión remota.
  */
  handleSessionDescription(config: RemoteConfig) {
    console.log('Remote Session Description', config);
    const { peerId, sessionDescription } = config;
    const remoteDescription = new RTCSessionDescription(sessionDescription);
    this.peerConnections[peerId]
      .setRemoteDescription(remoteDescription)
      .then(() => {
        console.log('Set remote description done!');
        if (sessionDescription.type == 'offer') {
          console.log('Creating answer');
          this.peerConnections[peerId]
            .createAnswer()
            .then((localDescription: RTCSessionDescriptionInit) => {
              console.log('Answer description is: ', localDescription);
              this.peerConnections[peerId]
                .setLocalDescription(localDescription)
                .then(() => {
                  this.sendToServer('relaySDP', {
                    peerId: peerId,
                    sessionDescription: localDescription,
                  });
                  console.log('Answer setLocalDescription done!');
                })
                .catch((err: Error) => {
                  console.error('[Error] answer setLocalDescription', err);
                });
            })
            .catch((err: Error) => {
              console.error('[Error] creating answer', err);
            });
        }
      })
      .catch((err: Error) => {
        console.error('[Error] setRemoteDescription', err);
      });
  }
  /**
  * Maneja un candidato ICE para añadirlo para establecer la conexión entre pares.
  * @param {Object} config - La configuración del candidato ICE.
  * @param {string} config.peerId - El ID del par.
  * @param {RTCIceCandidateInit} config.iceCandidate - El candidato ICE.
  */
  handleIceCandidate(config: CandidateConfig) {
    const { peerId, iceCandidate } = config;
    this.peerConnections[peerId].addIceCandidate(new RTCIceCandidate(iceCandidate)).catch((err: Error) => {
      console.error('[Error] addIceCandidate', err);
    });
  }
  handleDisconnect() {
    console.log('Disconnected from signaling server');
    this.utilesService.playSound('colgar');
    for (let peerId in this.peerMediaElements) {
      const peerMediaElement = this.peerMediaElements[peerId];
      if (peerMediaElement.parentNode) {
        peerMediaElement.parentNode.removeChild(peerMediaElement);
      }
    }
    for (let peerId in this.peerConnections) {
      this.peerConnections[peerId].close();
    }
    this.peerConnections = {};
    this.peerMediaElements = {};
    this.dataChannels = {};

    const clientComponentInstance = this;
    clientComponentInstance.saveLocalStorageConfig();
  }
  /**
  * Maneja la eliminación de un par de la sala.
  * @param {Object} config - La configuración de la eliminación del par.
  * @param {string} config.peerId - El ID del par.
  */
  handleRemovePeer(config: EliminaConfig) {
    console.log('Signaling server said to remove peer:', config);
    // Desactivamos el audio y video
    if(this.actdesavclientBtn)
    this.actdesavclientBtn.className=this.className.userOff;
    console.log('Añadimos el audio a off');
    this.localMediaStream.getAudioTracks()[0].enabled = false;
    this.utilesService.playSound('soundoff');
    const { peerId } = config;

    const container = document.getElementById('bodyclient'); // Obtener el contenedor
    if (container && peerId in this.peerMediaElements && this.peerMediaElements[peerId].parentNode) {
      container.removeChild(this.peerMediaElements[peerId].parentNode);
    } else {
      console.error('Container not found or peer element not in DOM');
    }

    if (peerId in this.peerConnections) this.peerConnections[peerId].close();

    this.currentPeerId = null;

    delete this.dataChannels[peerId];
    delete this.peerConnections[peerId];
    delete this.peerMediaElements[peerId];

    console.log('Peers count: ' + Object.keys(this.peerConnections).length);
    this.utilesService.playSound('leave');
  }

  /**
  * Maneja los mensajes entrantes del canal de datos.
  * @param {Object} config - Configuración del mensaje entrante.
  */
  handleIncomingDataChannelMessage(config: DataChannelMessage) {
    switch (config.type) {
      default:
        break;
    }
  }
  /**
  * Maneja los eventos de la interfaz de usuario.
  */
  handleEvents() {
    if (this.audioBtn)
      this.audioBtn.onclick = (e: Event) => {
        this.setAudioStatus(!this.localMediaStream.getAudioTracks()[0].enabled, e);
      };
    if (this.homeBtn) {
      this.homeBtn.onclick = () => {
        this.utilesService.playSound('colgar');
        this.endCall();
      };
    }; //Botón para activar y desactivar la cámara del cliente en remoto, además activa/desactiva la propia
    if (this.actdesavclientBtn)
      this.actdesavclientBtn.onclick = () => {
        console.log('Quiero activar/desactivar las cámaras');
        if (this.currentPeerId) { //Si estamos conectado a algún pier actualmente
          if (this.actdesavclientBtn)
            this.actdesavclientBtn.className = this.actdesavclientBtn.className == this.className.user ? this.className.userOff : this.className.user; // Si está apagado el botón, lo encendemos
          const textToSend = `Conectame la cam y el audio del peer ${this.currentPeerId}`; //Guardamos el texto a mandar
          console.log('Texto a enviar al servidor:', textToSend);  //Mostramos por consola
          this.sendTextToServer(this.currentPeerId, textToSend); //Se envía al server el texto con el id del peer
        } else {
          console.log('Error, no existe peer actualmente'); //Se muestra por consola el error en caso de no existir en un peer
        }
      } //Botón para hacer que el cliente se desconecte de la llamada en remoto

    if (this.apagaclientBtn)
      this.apagaclientBtn.onclick = () => {
        console.log('Quiero apagar al cliente');
        if (this.currentPeerId) {
          const textToSend = `Apaga al peer ${this.currentPeerId}`; //Guardamos el texto a mandar
          console.log('Texto a enviar al servidor:', textToSend);  //Mostramos por consola
          this.apagaCliente(this.currentPeerId, textToSend);
          this.endCall();
        } else {
          console.log('Error, no existe peer actualmente'); //Se muestra por consola el error en caso de no existir en un peer
        }
      }
  }

  /**
  * Maneja el estado de un par remoto en función del elemento especificado.
  * @param {Object} config - La configuración del estado del par remoto.
  * @param {string} config.element - El elemento al que se refiere el estado ('video' o'audio' ).
  * @param {string} config.peerId - El identificador del par remoto.
  * @param {boolean} config.active - El estado activo o inactivo del elemento.
  * @category Interacción con los pares remotos
  */
  handlePeerStatus(config: PeerStatusConfig) {
    const { element, peerId, active } = config;
    switch (element) {
      case 'video':
        this.setPeerVideoStatus(peerId, active);
        break;
      case 'audio':
        this.setPeerAudioStatus(peerId, active);
        break;
    }
  }

  /**
  * Maneja la rotación de un elemento de video.
  * @param {HTMLElement} rotateBtn - El botón para rotar el video.
  * @param {HTMLVideoElement} videoMedia - El elemento de video a rotar.
  * @category Interacción con la IU
  */
  handleVideoRotate(rotateBtn: HTMLElement, videoMedia: HTMLVideoElement) {
    let currentRotation = 0;
    rotateBtn.onclick = () => {
      currentRotation += 90;
      videoMedia.style.transform = `rotate(${currentRotation}deg)`;
    };
  }
  /**
  * Maneja la funcionalidad de pantalla completa para un elemento de video.
  * @param {HTMLElement} fullScreenBtn - El botón para activar/desactivar pantalla completa.
  * @param {HTMLElement} videoWrap - El contenedor del video.
  * @param {HTMLVideoElement} videoMedia - El elemento de video.
  * @category Interacción con la IU
  */
  handleFullScreen(fullScreenBtn: HTMLElement, videoMedia: HTMLVideoElement) {
    if (!this.utilesService.isFullScreenSupported()) {
      return this.utilesService.elemDisplay(fullScreenBtn, false);
    }

    fullScreenBtn.onclick = () => {
      if (this.utilesService.isFullScreen()) {
        this.utilesService.goOutFullscreen();
        fullScreenBtn.className = this.className.fullScreenOn;
      } else {
        fullScreenBtn.className = this.className.fullScreenOff;
      }
    };
    videoMedia.onclick = () => {
      if (this.isDesktopDevice) fullScreenBtn.click();
    };
  }

  /**
   * Establece el estado de video del par remoto.
   * @param {string} peerId - El identificador del par remoto.
   * @param {boolean} active - El estado activo o inactivo del video.
   * @category Interacción con los pares remotos
   */
  setPeerVideoStatus(peerId: string, active: boolean) {
    console.log(`Remote PeerId ${peerId} video status`, active);
    const peerVideoAvatarImage = document.getElementById(peerId + '_remoteVideoAvatar');
    this.utilesService.elemDisplay(peerVideoAvatarImage, active ? false : true);
  }

  /**
   * Establece el estado de audio del par remoto.
   * @param {string} peerId - El identificador del par remoto.
   * @param {boolean} active - El estado activo o inactivo del audio.
   * @category Interacción con los pares remotos
   */
  setPeerAudioStatus(peerId: string, active: boolean) {
    console.log(`Remote PeerId ${peerId} audio status`, active);
    const peerAudioStatus = document.getElementById(peerId + '_remoteAudioStatus');
    if (peerAudioStatus) peerAudioStatus.className = active ? this.className.audioOn : this.className.audioOff;
  }

  /**
   * @module client:setMedia
   */
  /**
   * Configura los medios locales (audio y video).
   * @param {Function} callback - Función de devolución de llamada que se llama cuando se han configurado los medios locales con éxito.
   * @param {Function} [errorBack] - Función de devolución de llamada que se llama en caso de error al configurar los medios locales.
   */
  async setupLocalMedia(callback: () => void, errorBack?: (error: Error) => void) {
    if (this.localMediaStream) {
      if (callback) callback();
      return;
    }

    try {
      const audioDeviceId = this.localStorageConfig.audio.devices.select ? this.localStorageConfig.audio.devices.select.value : undefined;
      //const videoDeviceId = this.localStorageConfig.video.devices.select ? this.localStorageConfig.video.devices.select.value : undefined;


      const audioConstraints: MediaTrackConstraints = typeof audioDeviceId === 'string' ? { deviceId: { exact: audioDeviceId } } : audioDeviceId ? { deviceId: { exact: undefined } } : {};
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: audioConstraints,
        video: true,
      });
      stream.getVideoTracks()[0].enabled = false;
      stream.getAudioTracks()[0].enabled = false;
      this.setLocalMedia(stream);
      if (callback) callback();
    } catch (err) {
      this.utilesService.playSound('error');
      console.error('[Error] access denied for audio/video', err);

      // Manejo del error si se proporciona una función de devolución de llamada de error
      if (errorBack) {
        errorBack(err as Error);
      } else {
        console.warn('No error callback provided for setupLocalMedia');
      }

      // Muestra un mensaje al usuario informando que no se pueden utilizar cámara ni micrófono
      popupMessage(
        'info',
        'GetUserMedia',
        `<p>You have joined the call without camera or microphone access.</p>`
      );
      if (callback) callback();
    }
  }

  /**
   * Establece los medios remotos (audio/video) y crea los elementos necesarios para mostrarlos.
   * @param {MediaStream} stream - El flujo de medios remoto.
   * @param {Object} peers - Objeto que contiene información sobre los pares.
   * @param {string} peerId - El ID del par.
   */

  /**
   * Establece los medios locales (audio/video) y crea los elementos necesarios para mostrarlos.
   * @param {MediaStream} stream - El flujo de medios local.
   */
  setLocalMedia(stream: MediaStream) {
    console.log('Access granted to audio/video');
    this.localMediaStream = stream;
    this.utilesService.logStreamSettingsInfo('localMediaStream', this.localMediaStream);
  }

  setRemoteMedia(stream: MediaStream, peers: { [key: string]: any }, peerId: string) {
    this.remoteMediaStream = stream;
    if (!(peerId in peers)) {
      console.warn('Peer not found', peerId);
      return;
    }

    const peerInfo = peers[peerId];
    const peerName = peerInfo.peerName;
    const peerVideo = peerInfo.peerVideo;
    const peerAudio = peerInfo.peerAudio;
    const remoteVideoWrap = document.createElement('div');
    remoteVideoWrap.style.position = 'relative'; // Cambia la posición a relativa
    const remoteMedia = document.createElement('video');
    const remoteVideoHeader = document.createElement('div');
    const remoteVideoFooter = document.createElement('div');
    const remoteVideoPeerName = document.createElement('h4');
    const remoteFullScreenBtn = document.createElement('button');
    const remoteVideoRotateBtn = document.createElement('button');
    const remoteAudioStatusIcon = document.createElement('button');
    const remoteVideoAvatarImage = document.createElement('img');
    remoteVideoHeader.id = peerId + '_remoteVideoHeader';
    remoteVideoHeader.classList.add('videoHeader', 'animate__animated', 'animate__fadeInDown', 'animate__faster');
    remoteVideoFooter.id = peerId + '_remoteVideoFooter';
    remoteVideoFooter.classList.add('remoteVideoFooter');
    remoteVideoPeerName.id = peerId + '_remotePeerName';
    remoteVideoPeerName.innerText = peerName;
    remoteFullScreenBtn.id = peerId + '_remoteFullScreen';
    remoteFullScreenBtn.className = this.className.fullScreenOn;
    remoteVideoRotateBtn.id = '_remoteVideoRotate';
    remoteVideoRotateBtn.className = this.className.rotate;
    remoteAudioStatusIcon.id = peerId + '_remoteAudioStatus';
    remoteAudioStatusIcon.className = this.className.audioOn;
    remoteVideoAvatarImage.id = peerId + '_remoteVideoAvatar';
    remoteVideoAvatarImage.src = this.image.camOff;
    remoteVideoAvatarImage.classList.add('videoAvatarImage');
    remoteVideoHeader.appendChild(remoteFullScreenBtn);
    remoteVideoHeader.appendChild(remoteVideoRotateBtn);
    remoteVideoHeader.appendChild(remoteAudioStatusIcon);
    remoteVideoFooter.appendChild(remoteVideoPeerName);
    remoteMedia.id = peerId + '_remoteVideo';
    remoteMedia.playsInline = true;
    remoteMedia.autoplay = true;
    remoteMedia.controls = false;
    this.peerMediaElements[peerId] = remoteMedia;
    remoteVideoWrap.id = peerId + '_remoteVideoWrap';
    remoteVideoWrap.classList.add('remoteVideoWrap');
    remoteVideoWrap.appendChild(remoteVideoHeader);
    remoteVideoWrap.appendChild(remoteVideoFooter);
    remoteVideoWrap.appendChild(remoteVideoAvatarImage);
    remoteVideoWrap.appendChild(remoteMedia);
    document.body.appendChild(remoteVideoWrap);
    this.attachMediaStream(remoteMedia, this.remoteMediaStream);
    this.handleFullScreen(remoteFullScreenBtn, remoteMedia);
    this.handleVideoRotate(remoteVideoRotateBtn, remoteMedia);
    this.setPeerVideoStatus(peerId, peerVideo);
    this.setPeerAudioStatus(peerId, peerAudio);
    this.utilesService.setTippy(remoteFullScreenBtn, 'Toggle full screen', 'bottom');
    this.utilesService.setTippy(remoteVideoRotateBtn, 'Rotate video', 'bottom');
    this.utilesService.setTippy(remoteAudioStatusIcon, 'Audio status', 'bottom');
    this.utilesService.setTippy(remoteVideoPeerName, 'Username', 'top');
    const peerVideoAvatarImage = document.getElementById(peerId + '_remoteVideoAvatar');
    this.utilesService.elemDisplay(peerVideoAvatarImage, true);

    const container = document.getElementById('bodyclient'); // Obtener el contenedor
    if (container) {
      container.appendChild(remoteVideoWrap); // Agregar el elemento al contenedor si existe
    } else {
      console.error('Container not found');
    }
  }



  /**
   * Actualiza el flujo local de audio.
   * @param {MediaStream} stream - Nuevo flujo de audio.
   * @category ManipulacióndelDOM
   */
  refreshMyLocalAudioStream(stream: MediaStream) {
    stream.getAudioTracks()[0].enabled = true; // Asegúrate de que el audio esté habilitado
    const newStream = new MediaStream([stream.getAudioTracks()[0]]);
    this.localMediaStream = newStream;
    this.attachMediaStream(this.myAudio, this.localMediaStream); // Cambia a myAudio si corresponde
    this.utilesService.logStreamSettingsInfo('refreshMyLocalAudioStream', this.localMediaStream);
  }

  /**
   * Actualiza el flujo local de audio para los pares conectados.
   * @param {MediaStream} stream - El nuevo flujo de audio.
   * @category Manipulación de Medios
   */
  refreshMyLocalAudioStreamToPeers(stream: MediaStream) {
    if (!this.thereIsPeerConnections()) return;
    for (let peerId in this.peerConnections) {
      let audioSender = this.peerConnections[peerId]
        .getSenders()
        .find((s: RTCRtpSender) => (s.track ? s.track.kind === 'audio' : false));
      if (audioSender) audioSender.replaceTrack(stream.getAudioTracks()[0]);
    }
  }


  /**
   * Establece el estado del audio.
   * @param {boolean} active - Indica si el audio está activo o no. Por defecto, es verdadero.
   * @param {Event} e - Evento que desencadenó la función. Por defecto, es falso.
   */
  setAudioStatus(active = true, e: Event) {
    console.log(`This PeerId ${this.thisPeerId} audio status`, active);
    if (active) { this.utilesService.playSound('soundon'); }
    else {
      this.utilesService.playSound('soundoff');
    }
    this.isAudioStreaming = active;
    this.setAudioButtons(active, e);
    this.emitPeerStatus('audio', active);
    this.localStorageConfig.audio.init.active = active;
    this.saveLocalStorageConfig();
  }


  /**
   * Establece los botones relacionados con el audio.
   * @param {boolean} active - Indica si el audio está activo o no.
   * @param {Event} e - Evento que desencadenó la función.
   */
  setAudioButtons(active: boolean, e: Event) {
    console.log(`El estado del audio es: `, active);

    if (this.audioBtn) {
      this.audioBtn.className = active ? this.className.audioOn : this.className.audioOff;
    }

    if (this.myAudioStatusIcon) {
      this.myAudioStatusIcon.className = active ? this.className.audioOn : this.className.audioOff;
    }

    if (this.localMediaStream && this.localMediaStream.getAudioTracks().length > 0) {
      this.localMediaStream.getAudioTracks()[0].enabled = active;
    } else {
      console.error('No se encontró ningún track de audio en el media stream local.');
    }
  }



  /**
   * Guarda la configuración actual en el almacenamiento local.
   */
  saveLocalStorageConfig() {
    this.localStorageConfig.audio.devices.count = this.LS.DEVICES_COUNT.audio;
    this.LS.setConfig(this.localStorageConfig);
  }

  /**
   * Carga la configuración previa desde el almacenamiento local.
   */
  loadLocalStorageConfig() {
    if (this.audioSource) {
      this.audioSource.selectedIndex = this.localStorageConfig.audio.devices.select.index;
    } else {
      console.error('El elemento audioSource es nulo.');
    }
  }

  /**
   * Obtiene las restricciones para la captura de audio.
   * @param {string|boolean} deviceId - ID del dispositivo de audio o false para utilizar el dispositivo predeterminado.
   * @returns {Object|boolean} Restricciones de audio o true para utilizar el dispositivo predeterminado.
   */
  getAudioConstraints(deviceId = false) {
    if (deviceId) return { deviceId: deviceId };
    return true;
  }


  /**
   * Función para enviar al server un mensaje y que este lo reenvíe al cliente, provocando que se apague y encienda la cámara del cliente
   * @param {string} peerId El ID del par al que se enviará el mensaje.
   * @param {string} text El texto que se enviará al servidor.
   */
  sendTextToServer(peerId: string, text: string) {
    this.sendToServer('textMessage', { peerId: peerId, text: text });
  }
  
  /**
   * Función para enviar al server un mensaje y que este lo reenvíe al cliente, provocando que se desconecte de la sala
   * @param {string} peerId El ID del par al que se enviará el mensaje.
   * @param {string} text El texto que se enviará al servidor.
   */
  apagaCliente(peerId: string, text: string) {
    this.sendToServer('apagalo', { peerId: peerId, text: text });
  }

  /**
      * Maneja el evento de carga de la ventana.
      * Restablece el zoom de video y registra el evento de cambio de tamaño de la ventana.
      * @param {Event} event - El evento de carga de la ventana.
      */
  handleWindowLoad = (event: Event) => {
    if (typeof window !== 'undefined') {
      this.resetVideoZoom();
      window.onresize = this.resetVideoZoom;
    }
  }
  /**
      * Confirma la salida de la página y guarda la configuración local antes de cerrarla.
      * @param {Event} event - El evento que desencadena la salida de la página.
      */
  confirmExit = (event: Event) => {
    if (typeof window !== 'undefined') {
      console.log('onbeforeunload', event);
      const clientComponentInstance = this;
      clientComponentInstance.saveLocalStorageConfig();
    }
  }

}
